import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query keyQuery {
      img0: file(relativePath: { eq: "key_new_book.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img1: file(relativePath: { eq: "key_speech.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(relativePath: { eq: "key_zoom.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(relativePath: { eq: "key_online_course.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3cn: file(relativePath: { eq: "key_online_course_cn.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(relativePath: { eq: "key_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img5: file(relativePath: { eq: "key_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.KeyEvents" description="seo.desc.KeyEvents" />
      <Container className="keyevent-container">
        <Row className="pt-4">
	{ locale !== "en" && (
          <Col xs={12}>
            <h3>{tt("pages.KeyEvents.title1")}</h3>
            <div>{tt("pages.KeyEvents.desc1")}</div>
          </Col>
	)}
	{ locale === "en" && (
          <Col xs={12}>
            <h3>{tt("pages.KeyEvents.title1")}</h3>
            <div>
		{tt("pages.KeyEvents.desc1")}
		<a href="https://jhnsp.com">here </a>
		{tt("pages.KeyEvents.desc2")}
	    </div>
          </Col>
	)}
          <Col className="pt-4">
            <a href="https://jhnsp.com">
              {locale !== "zh-cn" && (
                <Img
                  className="rounded mx-auto d-block pb-3"
                  fluid={data.img3.childImageSharp.fluid}
                />
              )}
              {locale === "zh-cn" && (
                <Img
                  className="rounded mx-auto d-block pb-3"
                  fluid={data.img3cn.childImageSharp.fluid}
                />
              )}
            </a>
          </Col>
        </Row>
	{ locale !== "en" && (
        <Row className="pt-4">
          <Col sm={8} className="pb-3">
            <h5 className="font-weight-bold">{tt("pages.KeyEvents.title2")}</h5>
          </Col>
        </Row>
	)}
	{ locale !== "en" && (
        <Row>
          <Col xl={6} lg={6} md={6} sm={6} xs={12} className="item">
            <Img
              className="rounded mx-auto d-block pb-3"
              fluid={data.img1.childImageSharp.fluid}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={12} className="item">
            <Img
              className="rounded mx-auto d-block pb-3"
              fluid={data.img2.childImageSharp.fluid}
            />
          </Col>
        </Row>
	)}
	{ locale !== "en" && (
        <Row>
          <Col xl={6} lg={6} md={6} sm={6} xs={12} className="item">
            <Img
              className="rounded mx-auto d-block pb-3"
              fluid={data.img0.childImageSharp.fluid}
            />
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={12} className="item">
            <Img
              className="rounded mx-auto d-block pb-3"
              fluid={data.img4.childImageSharp.fluid}
            />
            <Img
              className="rounded mx-auto d-block pb-3"
              fluid={data.img5.childImageSharp.fluid}
            />
          </Col>
        </Row>
	)}
      </Container>
    </Layout>
  )
}

export default ThePage
